// TJ

$site: 'tj' !default;
$sitefull: 'thejourney' !default;
$sitedomain: 'thejourney.co' !default;

// Brand
$color-brand-primary: #152e42 !default;
$color-brand-primary-text: #ffffff !default;
$color-brand-secondary: #d72439 !default;
$color-brand-secondary-text: #ffffff !default;
$color-brand-tertiary: #1d1e1c !default;
$color-brand-tertiary-text: #ffffff !default;

// Font definitions
$font-map: (
        'le-monde-livre-book': (
                font-family: 'le-monde-livre-std',
                font-weight: 300
        ),
        'le-monde-livre-bold': (
                font-family: 'le-monde-livre-std',
                font-weight: 700
        ),
        'proxima-nova-regular': (
                font-family: 'proxima-nova',
                font-weight: 400
        ),
        'proxima-nova-semibold': (
                font-family: 'proxima-nova',
                font-weight: 600
        ),
        'proxima-nova-bold': (
                font-family: 'proxima-nova',
                font-weight: 700
        )

) !default;

// Font definitions
$font-paragraph: "le-monde-livre-book", Palatino, Times, serif !default;
$font-paragraph-extra-light: "le-monde-livre-book", Palatino, Times, serif !default;
$font-paragraph-light: "le-monde-livre-book", Palatino, Times, serif !default;
$font-paragraph-book: "le-monde-livre-book", Palatino, Times, serif !default;
$font-paragraph-medium: "le-monde-livre-book", Palatino, Times, serif !default;
$font-paragraph-semibold: "le-monde-livre-bold", Palatino, Times, serif !default;
$font-paragraph-bold: "le-monde-livre-bold", Palatino, Times, serif !default;
$font-heading-light: "proxima-nova-regular", Helvetica, Arial, sans-serif !default;
$font-heading-regular: "proxima-nova-regular", Helvetica, Arial, sans-serif !default;
$font-heading-semibold: "proxima-nova-semibold", Helvetica, Arial, sans-serif !default;
$font-heading-bold: "proxima-nova-bold", Helvetica, Arial, sans-serif !default;
$font-heading-xbold: "proxima-nova-bold", Helvetica, Arial, sans-serif !default;
$font-default: $font-paragraph-medium !default;